<template>
  <v-card title="Tags" class="pa-2">
    <v-chip-group selected-class="text-primary" column>
      <v-chip
        v-for="tag in tags"
        :key="tag"
        @click="$router.push(`/watch/chip=tags`)"
      >
        {{ tag }}
      </v-chip>
    </v-chip-group>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  data: () => ({
    tags: [
      "Work",
      "Home Improvement",
      "Vacation",
      "Food",
      "Drawers",
      "Shopping",
      "Art",
      "Tech",
      "Creative Writing",
    ],
  }),
});
</script>
<style lang="scss" scoped></style>
