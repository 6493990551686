<template>
  <v-bottom-navigation
    app
    style="
      width: auto;
      height: auto;
      bottom: 5vh;
      position: fixed;
      right: 5vh;
      left: none;
      background-color: transparent;
    "
  >
    <div class="d-flex flex-column" style="background-color: transparent">
      <v-btn width="50" height="50" class="mb-2">
        <v-icon>mdi-fan-speed-1</v-icon>
        <v-tooltip activator="parent" location="start" style="top: 0"
          >Chỉ 1 thẻ video</v-tooltip
        >
      </v-btn>
      <v-btn width="50" height="50">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </div>
  </v-bottom-navigation>
</template>

<script>
export default {
  props: {
    componentkey: {
      type: Number,
    },
  },
  data() {
    return {
      isDrawing: false,
      choice: "Weather",
      items: [
        { title: "Weather", to: "/" },
        { title: "News", to: "/news" },
        { title: "Notes", to: "/notes" },
      ],
    };
  },
  methods: {
    _onMouseMove() {
      if (this.isDrawing) {
        const _elementDrawing = document.getElementById("_circleSmart");
        _elementDrawing.style.transform = ``;
      }
    },
    _onMouseUp() {
      this.isDrawing = true;
    },
    _handlerLink(value) {
      this.$emit("updateComponentKey");
      return (this.choice = value);
    },
  },
  computed: {},
};
</script>

<style scoped>
#fixed {
  width: 100px;
  height: 100px;
}
</style>
