<template>
  <v-navigation-drawer
    class="side-nav pa-1 pt-4 d-flex"
    color="backgroundBody"
    id="appNavigationDrawer"
    v-if="$store.state.app.navigator"
    v-model="$store.state.app.navigator"
  >
    <v-card color="backgroundBody">
      <v-list color="backgroundBody">
        <v-list-item
          v-for="link in links"
          :key="link"
          :value="link"
          rounded="shaped"
          :to="link.to"
          link
          active-color="main"
          class="mb-2"
        >
          <template v-slot:prepend>
            <v-icon icon> {{ link.icon }} </v-icon>
          </template>
          <v-list-item-title class="font-weight-bold">{{
            link.text
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
    <v-divider inset></v-divider>
    <v-card-text
      ><sub
        >Đăng nhập để follow các tác giả, thích video và xem bình luận.</sub
      ></v-card-text
    >
    <core-a-need-login :class="'w-100 mt-2'" />
    <!-- <v-card color="backgroundBody">
      <v-list>
        <v-list-subheader>Tài khoản được đề xuất</v-list-subheader>
        <v-list-item
          v-for="link in links"
          :key="link"
          :value="link"
          rounded="shaped"
          :to="link.to"
          link
          active-color="main"
          class="mb-2"
        >
          <template v-slot:prepend>
            <v-icon icon> {{ link.icon }} </v-icon>
          </template>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <button>Xem thêm</button>
      </v-card-actions>
    </v-card> -->
    <v-divider inset></v-divider>
    <v-card class="mx-auto" max-width="376" color="backgroundBody">
      <v-card-title>
        <v-list-subheader>Khám phá</v-list-subheader>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-chip-group v-model="selection" selected-class="text-primary" column>
          <v-chip value="Tất cả hoạt động"> # Tất cả hoạt động </v-chip>
          <v-chip value="Thích"> # Thích </v-chip>
          <v-chip value="Bình luận"> # Bình luận </v-chip>
          <v-chip value="value"
            ><span style="text-overflow: ellipsis; overflow: hidden">
              Yêu Đơn Phương Là Gì (MEE Remix) - Mee Media & h0n & BHMedia
            </span></v-chip
          >
        </v-chip-group>
      </v-card-text>
      <v-card-text>
        <!-- <v-list :items="items" item-props lines="three">
          <template v-slot:subtitle="{ subtitle }">
            <div v-html="subtitle"></div>
          </template>
        </v-list> -->
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
    </v-card>
    <v-card color="backgroundBody">
      <v-card-text>
        <a
          target="_blank"
          href="https://pictogrammers.com/library/mdi/icon/account-multiple/"
          >© 2023 Nam Nam</a
        >
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
  <v-divider inset></v-divider>
</template>

<script>
export default {
  props: ["componentkey"],
  data() {
    return {
      selection: "Tất cả hoạt động",
      links: [
        {
          to: "/foryou",
          text: "Dành cho bạn",
          icon: "mdi-home",
        },
        {
          to: "/following",
          icon: "mdi-account-multiple",
          text: "Đang Follow",
        },
        {
          to: "/live",
          icon: "mdi-video",
          text: "Live",
        },
      ],
    };
  },
  methods: {},
  watch: {},
  computed: {},
  created() {},
};
</script>

<style>
.side-nav {
  border-right: none;
}
</style>
