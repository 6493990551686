<template>
  <v-main class="bg-backgroundBody">
    <router-view></router-view>
    <core-login-and-register
      v-if="$store.state.app.openDialog == 'login_and_register'"
      @update:openDialog="$store.dispatch('app/setOpenDialog', '')"
    ></core-login-and-register>
    <core-dialog-profile
      v-if="$store.state.app.openDialog == 'update_profile'"
      @update:openDialog="$store.dispatch('app/setOpenDialog', '')"
    >
      ></core-dialog-profile
    >
    <core-a-alert></core-a-alert>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      propsOpenDialog: this.openDialog,
    };
  },
  watch: {},
};
</script>

<style></style>
