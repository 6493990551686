<template>
  <div id="alert-popup">
    <v-alert
      v-if="$store.state.app.alert.open == 'alert'"
      :title="$store.state.app.alert.title"
      :text="$store.state.app.alert.text"
      :type="$store.state.app.alert.type"
    >
      <template v-slot:close>
        <v-icon
          @click="
            $store.dispatch('app/setAlert', {
              open: '',
              title: '',
              text: '',
              type: '',
            })
          "
          >mdi-close</v-icon
        >
      </template>
    </v-alert>
  </div>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
  props: {
    openDialog: {
      type: String,
      default: "",
    },
  },
  data() {},
  mounted() {
    setTimeout(() => {
      this.$store.dispatch("app/setAlert", {
        open: "",
        title: "",
        text: "",
        type: "",
      });
    }, 7890);
  },
});
</script>
<style lang="scss" scoped>
#alert-popup {
  position: fixed;
  z-index: 2147483650;
  bottom: 5%;
  left: 5%;
}
</style>
