import { VueCookieNext } from "vue-cookie-next";
import clients from "./clients";

export default {
  send: function (payload) {
    return clients.apiClient.post(
      `/comments`,
      { ...payload },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: VueCookieNext.getCookie("access_token"),
        },
      }
    );
  },
  getByVideoId: function (payload) {
    return clients.apiClient.get(`/comments/${payload}`);
  },
};
