import axios from "axios";
import { VueCookieNext } from "vue-cookie-next";
const apiRootUrl = process.env.VUE_APP_API_ROOT_URL;

const apiClient = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-camelcase": 1,
    "Access-Control-Allow-Origin": process.env.VUE_APP_API_ROOT_URL,
  },
});

apiClient.interceptors.response.use(undefined, function (err) {
  if (axios.isCancel(err)) throw err;
  // handlerRefreshToken(err);
});

export const handlerRefreshToken = function (err) {
  if (
    err.response?.status === 401 &&
    err.response.data.access_token === false
  ) {
    apiClient
      .post("/catch-token", {
        refresh_token: VueCookieNext.getCookie("refresh_token"),
        profile: JSON.parse(localStorage.getItem("profile")),
      })
      .then((result) => {
        VueCookieNext.removeCookie("access_token");
        VueCookieNext.setCookie("access_token", result.data.access_token);
        location.reload();
      })
      .catch(() => {
        VueCookieNext.removeCookie("access_token");
        VueCookieNext.removeCookie("email");
        VueCookieNext.removeCookie("refresh_token");
        VueCookieNext.removeCookie("sub");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("profile");
        location.reload();
      });
  }
  return;
};

export default apiClient;
