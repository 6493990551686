<template>
  <div id="main-container">
    <v-theme-provider :theme="$store.state.app.modeDark ? 'dark' : 'light'">
      <v-app id="inspire">
        <!-- <template v-if="$vuetify.display.mobile">
          <core-a-view />
          <core-a-bottom-navigation @update:theme="theme = $event" />
        </template> -->
        <!-- <template v-else> -->
        <core-app-bar v-if="$store.state.app.appbar" />
        <core-a-drawer />
        <core-a-view />
        <core-a-status-messege-animation />
        <!-- </template> -->
      </v-app>
    </v-theme-provider>
  </div>
</template>

<script>
import InitApp from "@/components/app/AppInit.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  created() {},
  watch: {},
  methods: {},
  mounted() {},
  setup() {},
  mixins: [InitApp],
};
</script>
<style lang="scss">
@import "./styles/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: "Play", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  scroll-margin-top: 20px;
}

a {
  list-style: none;
  text-decoration: none;
}

ul {
  list-style: none;
}

#main-container {
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#inspire {
  background-color: transparent;
}
</style>
