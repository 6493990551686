<template>
  <p class="bg-secondary mt-2">
    {{ message }} |
    <router-link target="_blank" v-if="url_redirect" :to="url_redirect">{{
      message_redirect
    }}</router-link>
  </p>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
export default defineComponent({
  props: {
    message: {
      type: String,
      default: "You need to login to submit your post.",
    },
    url_redirect: {
      type: String,
    },
    message_redirect: {
      type: String,
    },
  },
  setup() {},
  methods: {
    ...mapActions("app", ["getChoices", "getWeather"]),
  },
  data() {
    return {};
  },
  created() {},
});
</script>

<style></style>
