export default {
  apiVersion: "Phiên bản mới nhất v.0.0.1",
  choices: null,
  openDialog: "",
  modeDark: JSON.parse(window.localStorage.getItem("modeDark")),
  navigator: true,
  appbar: true,
  reloadPage: 1,
  alert: {
    open: "",
    title: "",
    text: "",
    type: "",
  },
};
