import ComponentWrapperStatusController from "@/components/content/components/wrapperController/StatusController.vue";
import ComponentWrapperMainController from "@/components/content/components/wrapperController/WrapperControllerMain.vue";
import ComponentWrapperSubController from "@/components/content/components/wrapperController/WrapperControllerSub.vue";
import ComponentWrapperMediaPreAndNextController from "@/components/content/components/wrapperController/MediaPrevAndNext.vue";

export default {
  ComponentWrapperStatusController,
  ComponentWrapperMainController,
  ComponentWrapperSubController,
  ComponentWrapperMediaPreAndNextController,
};
