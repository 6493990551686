<template>
  <v-card>
    <div class="container-video" id="container-video">
      <div id="mask-pin-virtual-container"></div>
      <div id="container-content">
        <div id="content-pin-video">
          <div
            v-if="isCommercial"
            class="premium-content"
            style="
              background: radial-gradient(
                  circle,
                  rgba(0, 0, 0, 0),
                  rgba(0, 0, 0, 0.55)
                ),
                url(https://vm.beeteam368.net/wp-content/uploads/2021/12/call-of-duty-8.jpg);
            "
          >
            <div class="premium-content_abs ui-position-xy-center text-center">
              <h2 class="text-white">Premium Content</h2>
              <p>
                <sub class="text-white"
                  >This content is for
                  <span class="bg-success pa-1 rounded-pill"
                    ><span class="pa-1 font-weight-bold"
                      >Extreme Level</span
                    ></span
                  >
                  only.</sub
                >
              </p>
              <div class="ma-2">
                <v-btn
                  class="font-weight-bold ma-2"
                  prepend-icon="mdi-plus"
                  color="main"
                >
                  <p>Choose a plan</p>
                </v-btn>
                <v-btn
                  class="font-weight-bold ma-2"
                  prepend-icon="mdi-image"
                  color="main"
                >
                  <p>Trailer</p>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="mask-play-video">
            <div class="mask-play-video_container">
              <div class="video-aciton_play-video ui-position-xy-center">
                <v-btn
                  v-show="isOneTime"
                  size="large"
                  @click="(isOneTime = false), onPlay()"
                >
                  <v-icon color="text-text"> mdi-play </v-icon></v-btn
                >
              </div>
            </div>
            <div
              v-show="isOneTime"
              v-if="video"
              @click="onPlay"
              class="mask-picture"
              v-bind:style="{
                'background-image': 'url(' + video.cover_picture + ')',
              }"
            ></div>
          </div>
          <div
            class="func-main-video content"
            :ref="containerVideoRef"
            @mouseover="handlerMouseOverVideo"
            @mouseout="handlerMouseOutVideo"
          >
            <video
              :ref="domVideoElement"
              :id="domVideoElement"
              loading="lazy"
              preload="auto"
              @click="isPause = !isPause"
              @loadedmetadata="loadDuration"
              @timeupdate="onTimeUpdate"
              :loop="isReplay"
              class="func-media-video video-with ui-position-xy-center bg-black"
              playsinline="true"
              x5-playsinline="true"
              webkit-playsinline="true"
              tabindex="2"
              mediatype="video"
              :src="video.video_url"
            ></video>
            <div class="func-layer-video">
              <img
                class="beeteam368-player-logo pos-top-left"
                :src="require('@/assets/images/main-logo-dark.png')"
              />
            </div>
            <div class="func-controls-video" :id="domControlsVideo">
              <v-row class="pa-4 align-center" align="center" justify="end">
                <v-col cols="12" class="d-flex align-center pa-0">
                  <v-progress-linear
                    class="linear-controls"
                    :clickable="true"
                    v-model="currentTime"
                    @update:modelValue="onChangeStreamCurrentTime"
                    :buffer-value="videoBufferValue"
                    min="0"
                    :max="duration"
                    height="8"
                    color="main"
                    bg-color="white"
                    :stream="true"
                  >
                  </v-progress-linear>
                </v-col>
                <v-col class="d-flex align-center justify-start pa-0">
                  <v-btn
                    @click="isPause ? onPause() : onPlay()"
                    size="x-small"
                    color="transparent"
                  >
                    <v-icon color="white">{{
                      isPause ? "mdi-pause" : "mdi-play"
                    }}</v-icon>
                  </v-btn>
                  <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                      <div class="pr-2" v-bind="props">
                        <v-btn
                          icon
                          color="transparent"
                          size="x-small"
                          @click="onHandlerVolume"
                          style="position: relative"
                          ><v-icon color="white" size="x-small">{{
                            videoPlayerMuted
                              ? "mdi-volume-mute"
                              : "mdi-volume-high"
                          }}</v-icon>
                        </v-btn>
                      </div>
                      <div
                        v-if="isHovering"
                        v-bind="props"
                        class="w-100 h-100"
                        :style="
                          isHovering ? { maxWidth: '100px' } : { width: '0px' }
                        "
                      >
                        <div
                          v-motion
                          :enter="{
                            opacity: 1,
                            x: 0,
                          }"
                          :initial="{ x: -20, opacity: 0 }"
                          :animate="{ x: 0, opacity: 1 }"
                          :key="isHovering"
                          class="pr-2"
                        >
                          <v-slider
                            v-if="isHovering"
                            v-model="volumeVideo"
                            color="white"
                            hide-details="auto"
                            v-bind="props"
                            size="x-small"
                            min="0"
                            max="100"
                            :thumb-size="12"
                            height="12px"
                          ></v-slider>
                        </div>
                      </div>
                      <span
                        class="currenttime ui-margin-x-auto py-2 text-white"
                        :style="
                          $vuetify.display.mobile ? { fontSize: '12px' } : ''
                        "
                        >{{ $filters.timeVideoPlayer(currentTime) }}</span
                      >
                      <span class="px-1 text-white">/</span>
                      <span
                        :style="
                          $vuetify.display.mobile ? { fontSize: '12px' } : ''
                        "
                        class="endtime ui-margin-x-auto py-2 text-white"
                        >{{ $filters.timeVideoPlayer(duration) }}</span
                      >
                    </template>
                  </v-hover>
                  <div></div>
                </v-col>
                <v-col class="d-flex align-center justify-end pa-0">
                  <v-btn
                    :color="isReplay ? 'main' : 'transparent'"
                    size="x-small"
                    @click="isReplay = !isReplay"
                    ><v-icon color="white">mdi-replay</v-icon
                    ><v-tooltip activator="parent" location="bottom"
                      >Replay</v-tooltip
                    ></v-btn
                  >
                  <v-btn
                    color="transparent"
                    size="x-small"
                    @click="onHandlerScreen"
                    ><v-icon color="white">mdi-fullscreen</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { ref } from "vue";
export default defineComponent({
  mounted() {
    console.log(this.video.video_url);
  },
  setup() {
    let refVolume = ref(null);
    return { refVolume };
  },
  props: {
    isCommercial: {
      type: Boolean,
      default: false,
    },
    mainVideo: {
      type: String,
      default: null,
    },
    isPlay: {
      type: Boolean,
      default: true,
    },
    statusVideo: {
      type: String,
      default: null,
    },
    playId: {
      type: String,
      default: null,
    },
    videoId: {
      type: String,
      default: null,
    },
    video: null,
  },
  emits: ["update:playId"],
  data() {
    return {
      videoErr: false,
      domVideoElement: `videoById-${this.$uuid.v4()}`,
      containerVideoRef: `containerVideoRef-${this.$uuid.v4()}`,
      domControlsVideo: `domControlsVideo-${this.$uuid.v4()}`,
      isPause: false,
      duration: "00:00",
      currentTime: null,
      isOneTime: true,
      videoPlayerMuted: false,
      isVideoFullScreen: false,
      isReplay: true,
      videoBufferValue: null,
      volumeVideo: 70,
      statusVideoVideo: this.statusVideo,
      playIdVideo: this.playId,
    };
  },
  methods: {
    loadDuration() {
      this.currentTime = this.$refs[`${this.domVideoElement}`].currentTime;
      this.duration = this.$refs[`${this.domVideoElement}`].duration;
    },
    onPlay() {
      this.isPause = true;
      this.$refs[`${this.domVideoElement}`].play();
    },
    onPause() {
      this.isPause = false;
      this.$refs[`${this.domVideoElement}`].pause();
    },
    onTimeUpdate() {
      this.currentTime = this.$refs[`${this.domVideoElement}`].currentTime;
      this.videoBufferValue = document
        .getElementById(this.domVideoElement)
        .buffered.end(
          document.getElementById(this.domVideoElement).buffered.length - 1
        );
    },
    onChangeStreamCurrentTime() {
      this.$refs[`${this.domVideoElement}`].currentTime = this.currentTime;
    },
    onHandlerVolume() {
      this.videoPlayerMuted = !this.videoPlayerMuted;
    },
    onHandlerScreen() {
      if (this.$refs[`${this.containerVideoRef}`]) {
        var refContainerTagVideo = this.$refs[`${this.containerVideoRef}`];
        this.isVideoFullScreen = !this.isVideoFullScreen;
        if (this.isVideoFullScreen) {
          if (refContainerTagVideo.requestFullscreen) {
            refContainerTagVideo.requestFullscreen();
          } else if (refContainerTagVideo.msRequestFullscreen) {
            refContainerTagVideo.msRequestFullscreen();
          } else if (refContainerTagVideo.mozRequestFullScreen) {
            refContainerTagVideo.mozRequestFullScreen();
          } else if (refContainerTagVideo.webkitRequestFullscreen) {
            refContainerTagVideo.webkitRequestFullscreen();
          }
        } else {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if (document.webkitFullscreenElement) {
            document.webkitExitFullscreen();
          } else if (refContainerTagVideo.webkitRequestFullscreen) {
            refContainerTagVideo.webkitRequestFullscreen();
          } else {
            refContainerTagVideo.requestFullscreen();
          }
        }
      }
    },
    async handlerMouseOverVideo() {
      var domControlsVideo = document.getElementById(this.domControlsVideo);
      domControlsVideo.style.opacity = "1";
      domControlsVideo.style.transform = "translateY(0)";
    },
    async handlerMouseOutVideo() {
      var domControlsVideo = document.getElementById(this.domControlsVideo);
      domControlsVideo.style.transform = "translateY(10px)";
      domControlsVideo.style.opacity = "0";
    },
  },
  watch: {
    currentTime: {
      handler(value) {
        return value;
      },
      deep: true,
    },
    videoPlayerMuted: {
      handler(value) {
        if (value) {
          this.refVolume = this.volumeVideo;
          this.volumeVideo = 0;
        }
        if (!value && this.volumeVideo == 0) this.volumeVideo = this.refVolume;
        this.$refs[`${this.domVideoElement}`].muted = value;
        return value;
      },
      deep: true,
    },
    isPause: {
      handler(value) {
        if (!value) {
          this.onPause();
        } else {
          this.onPlay();
        }
        return value;
      },
    },
    isReplay: {
      handler(value) {
        return value;
      },
    },
    volumeVideo: {
      handler(value) {
        value = Math.round(value);
        if (value === 0) {
          this.videoPlayerMuted = true;
        }
        if (this.videoPlayerMuted === true && value !== 0) {
          this.videoPlayerMuted = false;
          return value;
        }
        this.$refs[`${this.domVideoElement}`].volume = parseFloat(
          value / 100
        ).toFixed(2);
        return Math.round(value);
      },
    },
    statusVideoVideo: {
      handler(value) {
        return value;
      },
    },
    playIdVideo: {
      handler(value) {
        this.$emit("update:playId", value);
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.linear-controls {
  cursor: pointer;
}

.func-layer-video {
  position: absolute;
  z-index: 750;
}

.func-main-video {
  position: relative;
}

.func-controls-video {
  bottom: 0%;
  left: 0%;
  position: absolute;
  z-index: 2147483647;
  width: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0) 95%);
  transform: translateY(0px);
  transition: all 350ms ease-in;
}

.func-media-video {
  position: absolute;
  z-index: 1300;
}

.back-to-main-media {
  position: absolute;
  right: 5%;
  z-index: 500;
  top: 5%;
}

.container-video {
  #mask-pin-virtual-container {
    position: absolute;
    padding-top: 56.25%;
    z-index: 500;
  }

  .premium-content {
    object-fit: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 700;
    top: 0%;

    .premium-content_abs {
      position: relative;
      margin: 0% auto;
    }
  }

  position: relative;
  padding-top: 56.25%;
  height: 100%;

  .mask-play-video {
    position: absolute;
    width: 100%;
    z-index: 500;
    top: 0;

    .mask-play-video_container {
      position: relative;

      .video-aciton_main-video {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .video-aciton_play-video {
        padding-top: 56.25%;
        position: absolute;
      }
    }

    .mask-picture {
      object-fit: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      padding-top: 56.25%;
      background-size: contain !important;
    }
  }

  .content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 300;

    .video-with {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 300;
    }
  }
}

.abslt {
  position: absolute;
}

.back-to-main-media {
  z-index: 500;
}
</style>
