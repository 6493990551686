import { VueCookieNext } from "vue-cookie-next";
export default {
  setIsLoggedIn(state, payload) {
    window.localStorage.setItem("isLoggedIn", payload);
    state.isLoggedIn = payload;
  },
  setProfile(state, payload) {
    if (payload.account) {
      VueCookieNext.setCookie("sub", payload.account.sub);
      VueCookieNext.setCookie("email", payload.account.email);
      window.localStorage.setItem("profile", JSON.stringify(payload.account));
    }
    state.profile = payload.account;
  },
};
