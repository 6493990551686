import ComponentMainVideo from "@/components/content/components/videos/VideoMediaMain.vue";
import ComponentVideoPopupMain from "@/components/content/components/videos/WatchVideoMain.vue";
import ComponentVideoChildItem from "@/components/content/components/videos/VideoChildItem.vue";
import ComponentOnceVideo from "@/components/content/components/videos/VideoOnce.vue";

export default {
  ComponentMainVideo,
  ComponentVideoPopupMain,
  ComponentVideoChildItem,
  ComponentOnceVideo,
};
