<template>
  <v-app-bar
    class="w-100"
    color="backgroundBody"
    style="border-bottom: 1px solid rgb(var(--v-theme-border))"
  >
    <v-row class="d-flex justify-start align-center">
      <v-col>
        <router-link to="/">
          <h1 class="pl-4 text-h5 font-weight-bold text-text">
            Tốp Tốp
          </h1></router-link
        >
      </v-col>
      <v-col
        ><v-text-field
          density="compact"
          append-inner-icon="mdi-magnify"
          placeholder="Tìm kiếm tài khoản và video"
          variant="outlined"
          loading
          hide-details="auto"
          circle
        ></v-text-field
      ></v-col>
      <v-col>
        <v-item-group selected-class="bg-primary">
          <v-container
            class="d-flex justify-end align-center"
            style="gap: 12px"
          >
            <v-item>
              <v-btn
                prepend-icon="mdi-plus"
                variant="tonal"
                color="text"
                size="small"
                @click="
                  () => {
                    if (!this.isLoggedIn) {
                      this.setOpenDialog('login_and_register');
                    } else {
                      this.$router.push('/upload');
                    }
                  }
                "
              >
                <span style="text-transform: initial">Tải lên</span>
              </v-btn>
            </v-item>
            <v-item v-if="this.isLoggedIn">
              <v-btn icon size="small">
                <v-icon dark>mdi-send </v-icon>
                <v-tooltip activator="parent" location="bottom" style="top: 0"
                  >Tin nhắn</v-tooltip
                >
              </v-btn>
            </v-item>
            <v-item v-if="this.isLoggedIn">
              <v-menu location="bottom" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn icon v-bind="props" size="small">
                    <v-icon dark>mdi-message-text-outline </v-icon>
                    <v-tooltip
                      activator="parent"
                      location="bottom"
                      style="top: 0"
                      >Hộp thư</v-tooltip
                    >
                  </v-btn>
                </template>
                <v-card class="mx-auto" max-width="376">
                  <v-card-title>
                    <h2 class="text-h4">Thông báo</h2>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-chip-group
                      v-model="selection"
                      selected-class="text-primary"
                      mandatory
                    >
                      <v-chip value="Tất cả hoạt động">
                        Tất cả hoạt động
                      </v-chip>
                      <v-chip value="Thích"> Thích </v-chip>
                      <v-chip value="Bình luận"> Bình luận </v-chip>
                    </v-chip-group>
                  </v-card-text>
                  <v-card-text>
                    <v-list :items="items" item-props lines="three">
                      <template v-slot:subtitle="{ subtitle }">
                        <div v-html="subtitle"></div>
                      </template>
                    </v-list>
                  </v-card-text>
                  <v-divider class="mx-4"></v-divider>
                </v-card>
              </v-menu>
            </v-item>
            <core-a-need-login></core-a-need-login>
            <v-item>
              <v-menu location="bottom" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn icon v-bind="props" v-if="profile">
                    <template v-if="profile.picture">
                      <v-avatar
                        color="brown"
                        size="small"
                        :image="profile.picture"
                      >
                      </v-avatar>
                    </template>
                    <template v-else>
                      <v-avatar color="brown" size="small">
                        <span class="text-h5">
                          {{
                            profile.family_name + " " + profile.given_name
                          }}</span
                        >
                      </v-avatar>
                    </template>
                  </v-btn>
                  <v-btn icon v-bind="props" color="clear" v-else>
                    <v-icon>mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-card class="mx-auto" max-width="400" height="auto">
                  <v-list density="compact" class="w-100">
                    <template v-if="this.isLoggedIn">
                      <v-list-item
                        class="pa-2 w-100"
                        active-color="primary"
                        :to="`@${profile.sub}`"
                      >
                        <v-btn class="d-flex align-center w-100 justify-start">
                          <template v-slot:prepend>
                            <v-icon> mdi-account </v-icon>
                          </template>
                          <v-list-item-title class="font-weight-bold text-text"
                            >Xem hồ sơ cá nhân</v-list-item-title
                          >
                        </v-btn>
                      </v-list-item>
                    </template>
                    <v-list-item
                      class="pa-2 w-100"
                      v-for="actionRow in actionRowAccount"
                      :key="actionRow.text"
                      active-color="primary"
                    >
                      <v-btn class="d-flex align-center w-100 justify-start">
                        <template v-slot:prepend>
                          <v-icon> {{ actionRow.icon }}</v-icon>
                        </template>
                        <v-list-item-title class="font-weight-bold text-text">{{
                          actionRow.text
                        }}</v-list-item-title>
                      </v-btn>
                    </v-list-item>
                    <v-list-item active-color="primary" class="pa-2 w-100">
                      <v-btn class="d-flex align-center w-100">
                        <template v-slot:prepend>
                          <v-icon> mdi-weather-night </v-icon>
                        </template>
                        <v-list-item-title class="font-weight-bold text-text">
                          Chế độ tối
                        </v-list-item-title>
                        <v-switch
                          v-if="this.$store.state.app"
                          inset
                          hide-details
                          color="success"
                          class="px-2"
                          v-model="modeDark"
                        ></v-switch>
                      </v-btn>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item
                      active-color="primary"
                      class="pa-2 w-100"
                      v-if="this.isLoggedIn"
                      to="/logout"
                    >
                      <v-btn
                        class="d-flex align-center w-100 justify-start"
                        @click="handlerLogout"
                      >
                        <template v-slot:prepend>
                          <v-icon> mdi-exit-to-app </v-icon>
                        </template>
                        <v-list-item-title class="font-weight-bold text-text">
                          Đăng xuất
                        </v-list-item-title>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-item>
          </v-container>
        </v-item-group>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
import { defineComponent } from "vue";
import { VueCookieNext } from "vue-cookie-next";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  data: () => ({
    selection: "Tất cả hoạt động",
    items: [
      { type: "subheader", title: "Trước" },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<div data-e2e="inbox-list-item" class="tiktok-1t130x2-DivItemContainer exfus50"><div class="tiktok-1g5c96b-DivAvatarContainer exfus51"><a href="/@MS4wLjABAAAAzCjFhWE_NoTxGxeCPfdIMg0PimkyVL_RpKDIx_2GJd96t6AkrA-6YNiApFNsrB3F"><span shape="circle" data-e2e="" class="exfus52 tiktok-9zoew1-SpanAvatarContainer-StyledAvatar e1e9er4e0" style="width: 48px; height: 48px;"><img loading="lazy" alt="" src="https://p16-sign-sg.tiktokcdn.com/aweme/100x100/tos-alisg-avt-0068/dea3cd2ef3592525845dab983c7b7458.jpeg?x-expires=1676350800&amp;x-signature=eXj1wIjx7%2BaAhTEgIJnAzvrbzuM%3D" class="tiktok-1zpj2q-ImgAvatar e1e9er4e1"></span></a></div><div class="tiktok-14aeum4-DivContentContainer exfus56"><p data-e2e="inbox-title" class="tiktok-1mdj9uu-PTitleText exfus57"><a href="/@MS4wLjABAAAAzCjFhWE_NoTxGxeCPfdIMg0PimkyVL_RpKDIx_2GJd96t6AkrA-6YNiApFNsrB3F">30819652209</a></p><p data-e2e="inbox-content" class="tiktok-1475ywz-PDescText exfus510"> đã thích video của bạn. 2022-12-1</p></div><a class="tiktok-uilget-StyledLinkVideoCover exfus55" href="/@7168372788066239493/video/7171844509125397762?lang=vi-VN" style="background-image: url(&quot;https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/792569bf5aea485db813912dd48443b9_1669825192?x-expires=1676286000&amp;x-signature=Z5HJhDZpgmETJ1NWYrFkwysqZO0%3D&quot;);"></a></div>`,
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Summer BBQ",
        subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text-primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
    actionRowAccount: [{ text: "Tiếng việt", icon: "mdi-text" }],
    modeDark: true,
  }),
  computed: {
    ...mapGetters("app", ["getApp"]),
    ...mapGetters("auth", ["isLoggedIn", "profile"]),
  },
  methods: {
    ...mapActions("app", ["setOpenDialog"]),
    ...mapActions("auth", ["setIsLoggedIn"]),
    ...mapGetters("app", ["getModeDark"]),
    handlerOpenDialog(value) {
      if (this.openDialog === value) {
        this.$emit("update:openDialog", "");
      } else {
        this.$emit("update:openDialog", value);
      }
    },
    handlerLogout() {
      VueCookieNext.removeCookie("access_token");
      VueCookieNext.removeCookie("email");
      VueCookieNext.removeCookie("refresh_token");
      VueCookieNext.removeCookie("sub");
      localStorage.removeItem("profile");
      this.setIsLoggedIn(false);
      window.google.accounts.id.disableAutoSelect();
      location.reload();
    },
  },
  watch: {
    modeDark: {
      handler(value) {
        this.$store.dispatch("app/setModeDark", value);
        return value;
      },
    },
  },
  mounted() {
    this.getModeDark();
    this.modeDark = this.$store.state.app.modeDark;
  },
});
</script>
<style lang="scss" scoped></style>
