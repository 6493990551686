import moment from "moment";
export default {
  install(Vue) {
    Vue.config.globalProperties.$filters = {
      textHandler(value) {
        if (value === null || undefined) {
          return "--";
        } else {
          return value;
        }
      },
      currencyUSD(value) {
        return "$" + value;
      },
      timeVideoPlayer(value) {
        if (value == 0) return "00:00";
        if (value) {
          try {
            if (value >= 60 * 60) {
              value = moment.utc(Math.round(value) * 1000).format("HH:mm:ss");
            } else {
              value = moment.utc(Math.round(value) * 1000).format("mm:ss");
            }
            return value;
          } catch (error) {
            return "--:--";
          }
        } else {
          return "--:--";
        }
      },
    };
  },
};
