<template>
  <v-progress-linear
    class="mb-2"
    :active="true"
    :indeterminate="loading"
    color="primary"
    :height="4"
  ></v-progress-linear>
  <div class="el-mother-vieo-home">
    <template v-for="item in dataVideos" :key="item.id">
      <component-video-popup-main>
        <template #user
          ><component-media-title
            :user="item.account"
            :video_note="item.video.note"
            :follow="item.follow"
            :toPath="item.to_path_user"
          >
          </component-media-title
        ></template>
        <template #video>
          <component-main-video
            :statusVideo="statusVideo"
            :videoId="item.video.id"
            v-model:playId="playId"
            :isPlay="playId === item.video.id"
            :video="item.video"
            :toPathVideo="item.to_path_video"
          ></component-main-video>
        </template>
        <template #features>
          <component-wrapper-main-controller :toPathVideo="item.to_path_video">
            <template #statusController>
              <component-wrapper-status-controller></component-wrapper-status-controller>
            </template>
          </component-wrapper-main-controller>
        </template>
      </component-video-popup-main>
      <v-divider inset></v-divider>
    </template>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      statusVideo: "oneTag",
      playId: null,
      dataVideos: [],
      loading: false,
    };
  },
  watch: {
    playId: {
      handler(value) {
        return value;
      },
      deep: true,
    },
  },
  methods: {
    async fetchDataVideos() {
      this.loading = true;
      this.dataVideos = await this.api.Video.getList()
        .then((result) => {
          this.loading = false;
          return result.data.data;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchDataVideos();
  },
});
</script>
<style lang="scss" scoped></style>
