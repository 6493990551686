import auth from "./auth";
import app from "./app";
import breadcrumbs from "./breadcrumbs";
const modules = {
  auth: {
    namespaced: true,
    ...auth,
  },
  app: {
    namespaced: true,
    ...app,
  },
  breadcrumbs: {
    namespaced: true,
    ...breadcrumbs,
  },
};
export default modules;
