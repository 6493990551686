<template>
  <p class="text-h4">{{ breadcrumbs.name }}</p>
  <v-breadcrumbs :items="links">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
import breadcrumbs from "@/mixins/breadcrumbs";
export default {
  mixins: [breadcrumbs],
  data: () => ({
    links: [
      {
        text: "Home",
        disabled: false,
        href: "user",
      },
    ],
  }),
  mounted() {
    this.links = this.breadcrumbs.items;
  },
};
</script>
