<template>
  <div
    v-motion
    :enter="{
      opacity: 1,
      x: 0,
    }"
    :initial="{ x: -10, opacity: 0 }"
    :animate="{ x: 10, opacity: 1 }"
    :key="isHeart"
    @click="onHeart"
  >
    <v-btn stacked prepend-icon variant="text">
      <v-icon :color="isHeart ? 'pink-accent-3' : 'text'"> mdi-heart</v-icon>
      {{ numberOfEmoticon }}
    </v-btn>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {
      numberOfEmoticon: 115,
      isHeart: true,
    };
  },
  mounted() {},
  methods: {
    onHeart() {
      this.isHeart = !this.isHeart;
    },
  },
  watch: {
    isHeart: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.isHeart) this.numberOfEmoticon = this.numberOfEmoticon + 1;
        if (!this.isHeart) this.numberOfEmoticon = this.numberOfEmoticon - 1;
        return value;
      },
    },
  },
});
</script>
<style lang="scss" scoped></style>
