<template>
  <v-bottom-navigation
    v-model="value"
    dark
    mode="shift"
    density="comfortable"
    mandatory="true"
  >
    <v-btn value="home">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn value="discovery">
      <v-icon>mdi-apple-safari</v-icon>
    </v-btn>
    <v-btn value="create">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn value="notification">
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <v-btn value="account">
      <v-icon>mdi-image</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
  props: {
    theme: { default: "dark", required: true, type: String },
  },
  emits: ["update:theme"],
  data: () => ({
    value: "home",
  }),
  beforeMount() {
    this.$emit("update:theme", "dark");
  },
  watch: {
    value: {
      handler(value, oldValue) {
        console.log(value);
        if (!value) {
          value = oldValue;
        }
        return value;
      },
    },
  },
});
</script>
