<template>
  <v-sheet class="mt-6">
    <v-card>
      <v-card-title class="h2"> Reviews </v-card-title>
      <v-row>
        <v-col cols="6">
          <div class="d-flex align-center">
            <span class="mr-2 text-h6">Rate this </span>
            <template v-if="rating !== 0"
              ><v-sheet rounded="rounded" class="pa-1" color="main"
                ><p style="font-size: smaller">
                  Thanks for rating: ({{ rating }})<v-icon size="x-small"
                    >mdi-star-outline</v-icon
                  >
                </p>
              </v-sheet></template
            >
          </div>
          <v-rating
            v-model="rating"
            hover
            half-increments
            length="10"
            size="small"
          >
          </v-rating>
          <v-btn @click="rating = 0" prepend-icon="mdi-minus">Not Rated</v-btn>
        </v-col>
        <v-col cols="6">
          <div class="float-right">
            <v-progress-circular
              color="success"
              model-value="75"
              :size="55"
              :width="2"
              class="float-right ml-2"
            >
              {{ "75%" }}</v-progress-circular
            >
            <div class="d-flex">
              <v-icon size="small" class="mr-2">mdi-account-group</v-icon>
              <p>User Score</p>
            </div>
            <div class="d-flex">
              <v-icon size="small" class="mr-2">mdi-star</v-icon>
              <p>132 ratings</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-divider inset></v-divider>
    <v-card>
      <v-card-title>Descriptions:</v-card-title>
      <div class="text--text pa-5">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
        interdum felis a porttitor pulvinar. Nunc vel dapibus sem. Vestibulum
        suscipit, diam ac fermentum dapibus, nunc odio faucibus tellus, sit amet
        luctus nisl lacus ac felis. In id dapibus sem. Suspendisse vel tortor
        dui. Proin ultricies justo ac placerat venenatis. Aliquam sit amet dui
        consectetur, maximus diam vel, eleifend velit. Cras scelerisque lorem
        tortor, eget maximus enim molestie eu. Suspendisse est mi, vehicula ac
        sollicitudin eu, convallis ut justo. Sed nec sollicitudin velit. Cras
        molestie eu nibh nec mollis. Vestibulum maximus dapibus magna eu
        tristique. Nulla tristique ut dui et pellentesque. Pellentesque ut
      </div>
      <v-card-actions>
        <v-btn prepend-icon="mdi-chevron-double-down">Show more</v-btn>
      </v-card-actions>
    </v-card>
    <slot name="tags"></slot>
  </v-sheet>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {
      rating: 0,
    };
  },
  mounted() {},
  methods: {},
  watch: {},
});
</script>
<style lang="scss" scoped></style>
