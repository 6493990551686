import { defineComponent } from "vue";
// import { mapGetters } from "vuex";
export default defineComponent({
  computed: {},
  mounted() {
    console.log(this.$route.fullPath);
  },
  data() {
    return {
      numberOfComment: 0,
    };
  },
  beforeCreate() {},
});
