import clients from "./clients";
import { VueCookieNext } from "vue-cookie-next";
export default {
  async loginGoogle({
    email,
    family_name,
    given_name,
    picture,
    sub,
    email_verified,
  }) {
    return clients.apiClient.post(`/auth/o/google`, {
      email: email,
      family_name: family_name,
      given_name: given_name,
      picture: picture,
      sub: sub,
      email_verified: email_verified,
    });
  },
  async getInfo() {
    return clients.apiClient.post(
      "/auth/o/google/info",
      {
        refresh_token: VueCookieNext.getCookie("refresh_token"),
        email: VueCookieNext.getCookie("email"),
        sub: VueCookieNext.getCookie("sub"),
      },
      {
        headers: {
          Authorization: VueCookieNext.getCookie("access_token"),
        },
      }
    );
  },
  async getProfile(payload) {
    return clients.apiClient.get(`/auth/profile/${payload.sub}`);
  },
};
