import auth from "@/api/auth";
export default {
  setIsLoggedIn({ commit }, payload) {
    return commit("setIsLoggedIn", payload);
  },
  async loginInfo({ commit }) {
    var response = await auth.getInfo();
    commit("setProfile", response.data.data);
  },
  async setProfile({ commit }, payload) {
    return commit("setProfile", payload);
  },
};
