<template>
  <template v-if="!this.isLoggedIn">
    <v-btn
      :class="this.class"
      size="small"
      color="main"
      variant="flat"
      style="text-transform: inherit"
      @click="handlerOpenDialogLogin"
      >Đăng nhập</v-btn
    >
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    class: String,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("app", ["setOpenDialog"]),
    handlerOpenDialogLogin() {
      this.setOpenDialog("login_and_register");
    },
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },
  created() {},
  mounted() {},
};
</script>

<style></style>
