import CoreLoginAndRegister from "@/components/core/LoginAndRegister.vue";
import CoreABreadcrumbs from "@/components/core/ABreadcrumbs.vue";
import CoreALoadingForm from "@/components/core/LoadingForm.vue";
import CoreANeedLogin from "@/components/core/NeedLogin.vue";
import CoreDialogProfile from "@/components/core/DialogProfile.vue";
import CoreAAlert from "@/components/core/AAlert.vue";

export default {
  CoreLoginAndRegister,
  CoreABreadcrumbs,
  CoreALoadingForm,
  CoreANeedLogin,
  CoreDialogProfile,
  CoreAAlert,
};
