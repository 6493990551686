import ComponentCommentsReply from "@/components/content/components/comments/ReplyComments.vue";
import ComponentCommentReply from "@/components/content/components/comments/ReplyComment.vue";
import ComponentCommentsList from "@/components/content/components/comments/ListComments.vue";
import ComponentCommentsForm from "@/components/content/components/comments/FormComment.vue";
import ComponentMediaCommentsContainer from "@/components/content/components/comments/ContainerComments.vue";

export default {
  ComponentCommentsReply,
  ComponentCommentsList,
  ComponentCommentsForm,
  ComponentMediaCommentsContainer,
  ComponentCommentReply,
};
