export default {
  breadcrumbs: null,
};
// {
//   name: "Simple Listing – Grid View",
//   items: [
//     {
//       text: "Home",
//       disabled: false,
//       href: "user",
//     },
//     {
//       text: "Link",
//       disabled: false,
//       href: "user",
//     },
//   ],
// }
