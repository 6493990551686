<template>
  <v-sheet class="w-100">
    <v-card class="mx-auto">
      <v-card-subtitle> 18 Related Posts </v-card-subtitle>
      <v-card-title> Related Posts </v-card-title>
    </v-card>
    <v-card class="mx-auto">
      <v-container class="pa-1">
        <v-item-group v-model="selection" multiple>
          <v-row>
            <v-col v-for="(item, i) in items" :key="i" cols="12" md="4">
              <v-item v-slot="{ isSelected, toggle }">
                <object :data="item.src" type="image/png">
                  <img
                    class="w-100 h-100"
                    :src="item.src"
                    alt="Avatar.png"
                    loading="lazy"
                    @click="toggle"
                  />
                  <v-btn
                    :icon="isSelected ? 'mdi-heart' : 'mdi-heart-outline'"
                  ></v-btn>
                </object>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-container>
      <v-row justify="center">
        <v-btn color="main">Load More</v-btn>
      </v-row>
    </v-card></v-sheet
  >
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  data: () => ({
    items: [
      {
        src: "https://i.pinimg.com/236x/ad/58/8b/ad588b4fb6d39a3cfcbca058cf8f3ab5.jpg",
      },
      {
        src: "https://i.pinimg.com/236x/88/a4/78/88a478ee6261b890d3c21548869cb357.jpg",
      },
      {
        src: "https://i.pinimg.com/236x/54/06/36/5406365a590efd13b573fd5ccb59d5ef.jpg",
      },
      {
        src: "https://i.pinimg.com/236x/5e/4d/c8/5e4dc8737507e34d32cf818e355c67bc.jpg",
      },
    ],
    selection: [],
  }),
});
</script>
<style lang="scss" scoped></style>
