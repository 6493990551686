<template>
  <v-row class="align-center mt-2 w-100" align="center">
    <v-col cols="12" class="pa-0">
      <v-btn stacked prepend-icon variant="text" style="cursor: auto">
        <v-icon color="text"> mdi-eye </v-icon>
        4.8K
      </v-btn>
    </v-col>
    <v-col cols="12" class="pa-0">
      <div
        v-motion
        :enter="{
          opacity: 1,
          x: 0,
        }"
        :initial="{ x: -10, opacity: 0 }"
        :animate="{ x: 10, opacity: 1 }"
        :key="isHeart"
        @click="onHeart"
      >
        <v-btn stacked prepend-icon variant="text">
          <v-icon :color="isHeart ? 'pink-accent-3' : 'text'">
            mdi-heart</v-icon
          >
          {{ numberOfEmoticon }}
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" class="pa-0">
      <v-btn
        stacked
        prepend-icon
        variant="text"
        @click="$router.push(this.toPathVideo)"
      >
        <v-icon color="text"> mdi-comment</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pa-0">
      <v-btn stacked prepend-icon variant="text">
        <v-icon color="text"> mdi-share</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["toPathVideo"],
  data() {
    return {
      numberOfComment: 0,
      numberOfEmoticon: 115,
      isHeart: true,
    };
  },
  mounted() {},
  methods: {
    onHeart() {
      this.isHeart = !this.isHeart;
    },
  },
  watch: {
    isHeart: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.isHeart) this.numberOfEmoticon = this.numberOfEmoticon + 1;
        if (!this.isHeart) this.numberOfEmoticon = this.numberOfEmoticon - 1;
        return value;
      },
    },
  },
});
</script>
<style lang="scss" scoped></style>
