<template>
  <v-form class="d-flex" @submit.prevent :ref="refCommentReply">
    <v-text-field
      class="mb-2"
      density="compact"
      hide-details="auto"
      variant="outlined"
      placeholder="'@' a user to tag them in your comments"
      v-model="note_comment"
      :rules="[(v) => !!v || 'Field is required']"
    >
      <span class="text-red" v-if="reply">
        {{ reply.account.name }}
        <v-btn elevation="0" icon @click="$emit('update:reply', null)"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </span>
      <template v-slot:prepend-inner v-if="show_friends">
        <span style="position: relative">
          <div
            style="position: absolute; z-index: 1000; bottom: 10%"
            class="content-comment-show-friends"
          >
            <div
              class="comment-show-friends"
              v-for="(item, index) in friends_comments"
              :key="index"
              :value="index"
              @click="
                handlerFriendsComments({ name: item.name, sub: item.sub })
              "
            >
              <div class="friends_avatar pl-2 pr-2">
                <v-avatar :image="item.picture"></v-avatar>
              </div>
              <div class="friends-title pl-2 pr-2">
                <p class="name-friend text-text">{{ item.name }}</p>
                <p class="subtitle text-text">{{ item.subtitle }}</p>
              </div>
              <div class="friends_status pl-2 pr-2"><sub>Following</sub></div>
            </div>
          </div>
        </span></template
      >
      <template v-slot:append-inner>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="text"
              ><v-icon>mdi-emoticon-outline</v-icon
              ><v-tooltip activator="parent" location="top"
                >Click to add emojis</v-tooltip
              ></v-btn
            ></template
          >
          <v-list style="display: grid; grid-template-columns: repeat(4, 1fr)">
            <v-list-item
              v-for="(item, index) in emoticon"
              :key="index"
              :value="index"
              @click="note_comment = note_comment + item"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>
    <v-btn color="main" type="submit" variant="text" @click="submit"
      >Submit</v-btn
    >
  </v-form>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    reply: {
      type: Object,
      default() {
        return { to: false };
      },
    },
    reFetchData: {
      type: Number,
    },
  },
  data() {
    return {
      refCommentReply: `reply-${this.$uuid.v4()}`,
      formHasErrors: false,
      note_comment: "",
      emoticon: [
        "😀",
        "😃",
        "😄",
        "😁",
        "😆",
        "😅",
        "🤣",
        "😂",
        "🙂",
        "🙃",
        "😉",
        "😊",
        "😇",
        "🥰",
        "😍",
      ],
      friends_comments: [
        {
          name: "Join",
          sub: "1234",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUtgKWH2zFyWeMK4UmqDMqutygU35m5O3d9u_Hami8h2lnMZC_OINrMjw0zbNEYTVAc4c&usqp=CAU",
          subtitle: "Bảo",
        },
        {
          name: "Adam Adam Adam",
          sub: "12345",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfO5nU-Vv74GBHCq6kBkpGQBwPJ31d2p5UFD_8KdX3_bkoFMMLf-580fgXgMekfNSh2YQ&usqp=CAU",
          subtitle: "Đặng văn lâm",
        },
        {
          name: "Circle",
          sub: "123456",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfO5nU-Vv74GBHCq6kBkpGQBwPJ31d2p5UFD_8KdX3_bkoFMMLf-580fgXgMekfNSh2YQ&usqp=CAU",
          subtitle: "Đốm",
        },
      ],
      show_friends: false,
      push_friends: [],
    };
  },
  mounted() {},
  methods: {
    handlerFriendsComments(event) {
      this.note_comment = this.note_comment + event.name + " ";
      this.push_friends.push({
        name: event.name,
        sub: event.sub,
      });
    },
    async submit() {
      await this.$refs[`${this.refCommentReply}`].validate().then((success) => {
        if (!success.valid) {
          const errors = Object.entries(Object.entries(success.errorMessages))
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          let errorInputFirst = document.getElementById(
            `input-${errors[0].value[1].id + 1}`
          );
          errorInputFirst.scrollIntoView(true);
          this.formHasErrors = true;
        } else {
          this.formHasErrors = false;
        }
      });
      if (!this.formHasErrors) {
        if (this.reply) {
          console.log("@");
          await this.api.Comments.send({
            p_id: this.reply.account.p_id,
            f_id: this.reply.account.f_id,
            id_video: this.$route.params.idVideo,
            hash_user: this.push_friends,
            note: this.note_comment,
          }).then(() => {
            this.$emit("update:reFetchData", this.reFetchData + 1);
            this.note_comment = "";
            this.$emit("update:reply", null);
          });
        } else {
          await this.api.Comments.send({
            id_video: this.$route.params.idVideo,
            hash_user: this.push_friends,
            note: this.note_comment,
          }).then(() => {
            this.$emit("update:reFetchData", this.reFetchData + 1);
            this.note_comment = "";
            this.$emit("update:reply", null);
          });
        }
      }
    },
  },
  watch: {
    note_comment: {
      handler(value) {
        if (value.length === 0) return value;
        if (value.at(value.length - 1).includes("@")) {
          this.show_friends = true;
        } else {
          this.show_friends = false;
        }
        return value;
      },
    },
  },
  created() {},
});
</script>
<style lang="scss" scoped>
.content-comment-show-friends {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.comment-show-friends {
  width: 100%;
  max-width: 400px;
  display: flex;
  cursor: pointer;
  padding: 4px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .friends-title {
    display: flex;
    flex-direction: column;
  }

  .name-friend {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .subtitle {
    width: 100px;
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .friends_status {
    padding: 8px;
  }
}
</style>
