<template>
  <v-card class="mt-2">
    <v-card-title> 6 Comments </v-card-title>
    <v-list lines="three">
      <v-list-item v-for="item in items" :key="item.title">
        <div class="d-flex">
          <div class="mr-2">
            <object :data="item.prependAvatar" type="image/png">
              <img
                class="w-100 h-100"
                src="https://secure.gravatar.com/avatar/119915a6b9fb9c5149b70ee96a7bc1a6?s=122&d=mm&r=g%202x"
                alt="Avatar.png"
                loading="lazy"
              />
            </object>
          </div>
          <div style="margin-right: auto">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle
              ><div v-html="item.subtitle"></div
            ></v-list-item-subtitle>
            <sub class="text-sub">November 7, 2022 at 3:22 pm</sub>
            <div
              :id="'respond' + item.title"
              :data-comment-respond="item.title"
            >
              <component-comments-reply
                :replyId="item.title"
                v-model:showReplyId="showReplyId"
              ></component-comments-reply>
            </div>
          </div>
          <v-list-item-action>
            <v-btn
              variant="outlined"
              append-icon="mdi-reply"
              @click="showReplyId = item.title"
              >{{ $vuetify.display.mobile ? "" : "Reply" }}</v-btn
            >
          </v-list-item-action>
        </div>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn class="w-100">See more...</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {
      showReplyId: null,
      srcset:
        "https://cdn4.iconfinder.com/data/icons/movies-2-webby/60/083_-_Avatar-512.png",
      items: [
        {
          prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: "SummerBBQ",
          subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        {
          prependAvatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Ouioui",
          subtitle:
            '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
          reply: {
            prependAvatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
            title: "Replyoui",
            subtitle:
              '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
          },
        },
        {
          prependAvatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Birthdaygift",
          subtitle:
            '<span class="text-primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        {
          prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "Recipetotry",
          subtitle:
            '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {
    items: {
      handler(value) {
        return value;
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>
<style lang="scss" scoped></style>
