/* eslint-disable prettier/prettier */
import { createApp, h } from "vue";
import { sync } from "vuex-router-sync";

import App from "./App.vue";
import _vuetify from "./plugins/vuetify";
import { MotionPlugin } from '@vueuse/motion'
import router from "./router";
import store from "./store";
import { loadFonts } from "./plugins/webfontloader";
import argonKit from "../src/plugins/argon-kit";
import Filters from "./filters";
import * as Router from "vue-router";
// ANCHOR globalProperties
import _ from "lodash";
import api from "@/api";
import moment from "moment";
import * as uuid from "uuid";

// ANCHOR globalPropertiess
const app = createApp({ render: () => h(App) });
sync(store, router);
loadFonts();
argonKit.install(app);
Filters.install(app);
// ANCHOR globalProperties
app.config.globalProperties.$_ = _;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$uuid = uuid;
app.config.globalProperties.api = api;

app.use(_vuetify);
app.use(Router);
app.use(router);
app.use(store);
app.use(MotionPlugin)
app.mount("#app");
