import app_components from "@/components/app/_index";
import core_components from "@/components/core/_index";
import helper_components from "@/components/helper/_index";
import post_components from "@/components/post/_index";
import post_form_components from "@/components/post/form/_index";
import videos_components from "@/components/content/components/videos/_index";
import titles_components from "@/components/content/components/titles/_index";
import wrapper_controller_components from "@/components/content/components/wrapperController/_index";
import reviews_components from "@/components/content/components/reviews/_index";
import tags_components from "@/components/content/components/tags/_index";
import relayted_post_components from "@/components/content/components/relatedPost/_index";

import content_components from "@/components/content/containers/detail/_index";
import content_components_comments from "@/components/content/components/comments/_index";

export const components = {
  ...app_components,
  ...core_components,
  ...helper_components,
  ...post_components,
  ...post_form_components,
  ...content_components,
  ...content_components_comments,
  ...videos_components,
  ...titles_components,
  ...wrapper_controller_components,
  ...reviews_components,
  ...tags_components,
  ...relayted_post_components,
};
export default {
  install(Vue) {
    Object.keys(components).forEach((componentName) => {
      Vue.component(componentName, components[componentName]);
    });
  },
};
