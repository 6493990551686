import globalComponents from "./globalComponents";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VideoBackground from "vue-responsive-video-background-player";
import { VueCookieNext } from "vue-cookie-next";
export default {
  install(Vue) {
    Vue.use(VueCookieNext);
    VueCookieNext.config({
      expireTimes: "7d",
      secure: true,
      path: "/",
      domain: "",
      sameSite: "",
    });
    Vue.use(globalComponents);
    if (process.env.VUE_APP_GOOGLE_MAP_KEY) {
      Vue.use(VueGoogleMaps, {
        load: {
          key: "AIzaSyCVH4DyrhEds0v8nQgGBHYUK6nsDcwlA38",
        },
      });
    }
    Vue.component("video-background", VideoBackground);
  },
};
