import { choiceSelect } from "../../../utils/choices";

export default {
  getApp: (state) => {
    return choiceSelect(state, "app");
  },
  getOnlyView: (state) => {
    return choiceSelect(state, "only_view");
  },
  getAccessUser(state) {
    return choiceSelect(state, "access_user");
  },
  getModeDark: (state) => {
    return state.modeDark;
  },
  getNavigator: (state) => state.navigator,
  getAppbar: (state) => state.appbar,
};
