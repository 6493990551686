<template>
  <div class="d-flex justify-space-between align-center">
    <div class="d-flex align-center">
      <v-avatar :image="item.user.picture"></v-avatar>
      <div class="px-2">
        <p>
          {{ `${item.user.given_name} ${item.user.family_name}` }}
        </p>
        <p id="comment">
          {{ item.note }}
        </p>
        <div>
          5m ago
          <v-btn
            variant="text"
            :data-reply="item.sub"
            @click="
              ($event) =>
                onReply($event, {
                  f_id: item.id,
                  p_id: item.p_id,
                  name: `${item.user.given_name} ${item.user.family_name}`,
                  sub: item.user.sub,
                })
            "
            >Reply</v-btn
          >
        </div>
      </div>
    </div>
    <v-btn
      class="d-flex flex-column align-items"
      elevation="0"
      @click="handlerHeartComment(item, index)"
      ><v-icon :color="item.heart ? 'main' : 'text-text'"> mdi-heart </v-icon>
    </v-btn>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["item", "reply"],
  methods: {
    onReply(event, payload) {
      this.$emit("update:reply", {
        to: true,
        account: {
          f_id: payload.f_id,
          p_id: payload.p_id,
          name: payload.name,
          sub: payload.sub,
        },
      });
    },
  },
});
</script>
