<template>
  <core-a-loading-form :loading="loading">
    <template #content>
      <slot name="loginMessage"></slot>
      <slot name="audio"></slot>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="pa-2 overflow-y-auto"
        enctype="multipart/form-data"
      >
        <p class="text-h5 text-primary font-weight-bold">Primary Source</p>
        <p class="text-h6 text-text">Media File Upload</p>
        <p class="text-sub">
          Supports: *.mp4, *.m4v, *.webm, *.ogv. Maximum upload file size: 10mb.
          You can change the upload size in "Theme Settings".
        </p>
        <v-card>
          <v-tabs v-model="tabMediaFile" bg-color="primary">
            <v-tab value="upload"
              ><v-tooltip text="Upload">
                <template v-slot:activator="{ props }">
                  <v-btn
                    variant="plain"
                    v-bind="props"
                    icon="mdi-upload"
                  ></v-btn>
                </template> </v-tooltip
            ></v-tab>
            <v-tab value="external_link">
              <v-tooltip text="External Link">
                <template v-slot:activator="{ props }">
                  <v-btn
                    variant="plain"
                    v-bind="props"
                    icon="mdi-link-variant"
                  ></v-btn>
                </template> </v-tooltip
            ></v-tab>
          </v-tabs>
          <div>
            <v-window v-model="tabMediaFile">
              <v-window-item value="upload">
                <v-file-input
                  class="mt-2"
                  v-model="fileUpload"
                  label="Video/audio file to upload"
                  :rules="rules.fileUpload"
                  accept="video/mp4,video/x-m4v,video/*,audio/mp3,audio/*;capture=microphone"
                  required
                  variant="outlined"
                  :show-size="1000"
                  counter
                ></v-file-input>
              </v-window-item>
              <v-window-item value="external_link">
                <v-text-field
                  class="pt-2"
                  v-model="fileUploadText"
                  :rules="[(e) => !!e || 'External link is required']"
                  placeholder="Placeholder"
                  label="Enter the media's external link or embed."
                  variant="outlined"
                ></v-text-field>
              </v-window-item>
            </v-window>
          </div>
        </v-card>
        <p class="text-h5 text-text">Video Resolution & Aspect Ratio</p>
        <v-text-field
          v-model="resolutionAspectRatio"
          label="Default: “ 16:9 ” - You can change the aspect ratio of this video to “ 2:3 ” , “ 21:9 ”, ... or “ auto ”"
          placeholder="Placeholder"
          variant="outlined"
        ></v-text-field>
        <p class="text-h5 text-primary font-weight-bold">Preview/Demo File</p>
        <v-card flat>
          <v-tabs v-model="tabPreviewFile" bg-color="primary">
            <v-tab value="upload"
              ><v-tooltip text="Upload">
                <template v-slot:activator="{ props }">
                  <v-btn
                    variant="plain"
                    v-bind="props"
                    icon="mdi-upload"
                  ></v-btn>
                </template> </v-tooltip
            ></v-tab>
            <v-tab value="external_link">
              <v-tooltip text="External Link">
                <template v-slot:activator="{ props }">
                  <v-btn
                    variant="plain"
                    v-bind="props"
                    icon="mdi-link-variant"
                  ></v-btn>
                </template> </v-tooltip
            ></v-tab>
          </v-tabs>
          <div>
            <v-window v-model="tabPreviewFile">
              <v-window-item value="upload">
                <v-file-input
                  class="mt-2"
                  v-model="previewfileUpload"
                  label="Video/audio file to upload"
                  :rules="rules.previewfileUpload"
                  accept="video/mp4,video/x-m4v,video/*,audio/mp3,audio/*;capture=microphone"
                  required
                  variant="outlined"
                  :show-size="1000"
                  counter
                ></v-file-input>
              </v-window-item>
              <v-window-item value="external_link">
                <v-text-field
                  class="pt-2"
                  placeholder="Placeholder"
                  label="Enter the media's external link or embed."
                  v-model="previewFileUploadText"
                  :rules="[(e) => !!e || 'External link is required']"
                  variant="outlined"
                ></v-text-field>
                {{ test }}
              </v-window-item>
            </v-window>
          </div>
        </v-card>
        <p class="text-h5 text-primary font-weight-bold">
          Video - Pay Per View
        </p>
        <p class="text-h6 text-text">Purchase Price</p>
        <p class="text-sub">
          If you want to sell access to this content, enter the sale price for
          it. FREE = 0 or blank
        </p>
        <v-text-field
          :rules="[
            (v) =>
              /^([0-9]{1,4}){1}(\.[0-9]{1,3})?$/gm.test(v) ||
              '9999 larger “Purchase Price” greater than or equal to 0',
          ]"
          v-model="purchasePrice"
          type="number"
          variant="outlined"
        ></v-text-field>
        <p class="text-h6 text-text">Post Title</p>
        <v-text-field
          v-model="postTitle"
          type="text"
          label="Enter the title of the post"
          placeholder="Placeholder"
          :rules="[(v) => !!v || 'Post Title is required']"
          variant="outlined"
        ></v-text-field>
        <p class="text-h6 text-text">Post Tags</p>
        <v-text-field
          v-model="postTags"
          type="text"
          label="Enter comma-separated values ex: #video #audio #blog #tags"
          placeholder="Placeholder"
          :rules="[(v) => !!v || 'Post Tags is required']"
          variant="outlined"
        ></v-text-field>
        <p class="text-h6 text-text">Video Categories</p>
        <v-select
          v-model="videoCategories"
          chips
          label="Select"
          :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
          ]"
          multiple
        ></v-select>
        <p class="text-h6 text-text">Featured Image</p>
        <p class="text-sub">
          Supports: *.png, *.jpg, *.gif, *.jpeg. Maximum upload file size: 5mb
        </p>
        <v-file-input
          class="mt-2"
          v-model="featuredImage"
          label="Image file to upload"
          :rules="rules.featuredImage"
          accept="*.png, *.jpg, *.gif, *.jpeg"
          required
          variant="outlined"
          :show-size="1000"
          counter
        ></v-file-input>
        <p class="text-h6 text-text">Post Descriptions</p>
        <v-textarea
          v-model="postDescriptions"
          type="text"
          rows="3"
          row-height="25"
          shaped
          label="Post Descriptions"
          placeholder="Placeholder"
          :rules="[(v) => !!v || 'Post Descriptions is required']"
          variant="outlined"
          no-resize
        ></v-textarea>
        <v-card-actions>
          <slot name="btnExit"></slot>
          <v-divider inset></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip v-if="formHasErrors" left>
            <template v-slot:activator="{ props }">
              <v-btn icon class="my-0" v-bind="props" @click="reset">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh form</span>
          </v-tooltip>
          <v-btn
            color="primary"
            text
            @click="submit"
            variant="outlined"
            :disabled="disabled"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-form>
    </template>
  </core-a-loading-form>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  data: () => ({
    test: "",
    tabMediaFile: "Upload",
    tabPreviewFile: "Upload",
    valid: true,
    disabled: false,
    formHasErrors: false,
    loading: false,
    fileUpload: [],
    previewfileUpload: [],
    fileUploadText: "",
    previewFileUploadText: "",
    resolutionAspectRatio: "",
    purchasePrice: 0,
    postTitle: "",
    videoCategories: [],
    featuredImage: [],
    postDescriptions: "",
    postTags: [],
    rules: {
      fileUpload: [
        (v) => !!v || "File is required",
        (v) =>
          (v[0] && v[0].size < 1500000000) ||
          "File is required and File size should be less than 100 MB!",
      ],
      previewfileUpload: [
        (v) =>
          (v[0] && v[0].size < 200000000) ||
          "File is required and File size should be less than 2 GB!",
      ],
      featuredImage: [
        (v) =>
          (v[0] && v[0].size < 50000000) ||
          "File is required and File size should be less than 500 MB!",
      ],
    },
  }),

  methods: {
    async submit() {
      this.disabled = true;
      this.formHasErrors = false;
      await this.$refs.form.validate().then((success) => {
        if (!success.valid) {
          const errors = Object.entries(Object.entries(success.errorMessages))
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          let errorInputFirst = document.getElementById(
            `input-${errors[0].value[1].id + 1}`
          );
          errorInputFirst.scrollIntoView(true);
          this.formHasErrors = true;
        } else {
          this.formHasErrors = false;
        }
      });
      if (!this.formHasErrors) {
        this.loading = true;
        let formData = new FormData();
        if (this.tabMediaFile === "external_link") {
          formData.append("file_upload", this.fileUploadText);
        } else {
          await formData.append("file_upload", this.fileUpload[0]);
        }
        if (this.tabPreviewFile === "external_link") {
          formData.append("file_upload", this.previewFileUploadText);
        } else {
          await formData.append(
            "perview_file_upload",
            this.previewfileUpload[0]
          );
        }
        formData.append("categories", "video-long");
        formData.append("resolution_aspect_ratio", this.resolutionAspectRatio);
        formData.append("purchase_price", this.purchasePrice);
        formData.append("post_title", this.postTitle);
        formData.append("video_categories", this.videoCategories);
        formData.append("featured_image", this.featuredImage[0]);
        formData.append("post_descriptions", this.postDescriptions);
        formData.append("post_tags", this.postTags);
        this.api.SubmitPost.send(formData)
          .then((result) => {
            console.log(result);
          })
          .catch((err) => console.err(err))
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 3000);
            this.formHasErrors = true;
          });
      }
      this.disabled = false;
    },
    async reset() {
      this.$refs.form.fileUpload = [];
      this.$refs.form.previewfileUpload = [];
      this.$refs.form.fileUploadText = "";
      this.$refs.form.previewFileUploadText = "";
      this.$refs.form.resolutionAspectRatio = "";
      this.$refs.form.purchasePrice = 0;
      this.$refs.form.postTitle = "";
      this.$refs.form.videoCategories = [];
      this.$refs.form.featuredImage = [];
      this.$refs.form.postDescriptions = "";
      this.$refs.form.postTags = [];
      this.formHasErrors = false;
      await this.$refs.form.resetValidation();
    },
  },
  watch: {},
  computed: {},
});
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
