/* eslint-disable prettier/prettier */
// Styles
import "@mdi/font/css/materialdesignicons.css";
// Vuetify
import { createVuetify } from "vuetify";
import "vuetify/styles";
import * as components from 'vuetify/components';
import { Ripple } from "vuetify/directives";
const vuetify = createVuetify({
  components,
  directives: {
    Ripple
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: true,
    options: { customProperties: true },
    themes: {
      dark: {
        colors: {
          sub: "#A0A0A0",
          main: "#DE0404",
          background: '#191A1D',
          surface: '#252525',
          primary: '#FF597B',
          'primary-text': "#FFFFFF",
          secondary: '#FF597B',
          success: '#009944',
          error: '#cf000f',
          warning: '#f0541e',
          info: '#63c0df',

          text: "#fff",
          backgroundBody: "#000",
          iconBackground: "#3C3F46",
          system: "#9393B7",
          border: "#383838",
          untext: "#000",
          white: "#FFF",
          gray: "#808080",


        }
      },
      light: {
        colors: {
          sub: "#A0A0A0",
          main: "#DE0404",
          primary: '#FF597B',
          surface: '#ffffff',
          secondary: '#FF597B',
          success: '#009944',
          error: '#cf000f',
          warning: '#f0541e',
          info: '#63c0df',
          text: "#000",
          iconBackground: "#3C3F46",
          background: '#ffffff',

          system: "#110378",
          backgroundBody: "#f8f8f8",
          border: "#FFF",
          untext: "#fff",
          white: "#FFF",
          gray: "#808080",

        }
      }
    }
  },
})

export default vuetify;