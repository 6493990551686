import actions from "@/store/modules/app/actions";
import getters from "@/store/modules/app/getters";
import mutations from "@/store/modules/app/mutations";
import state from "@/store/modules/app/state";

export default {
  actions,
  getters,
  mutations,
  state,
};
