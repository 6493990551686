<template>
  <v-row>
    <v-col cols="6">
      <v-card id="media-pre-action">
        <v-card-actions>
          <v-btn prepend-icon="mdi-arrow-left-thin">Prev</v-btn>
        </v-card-actions>
        <div class="d-flex pa-0">
          <v-avatar class="ma-3" size="125" rounded="0">
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/halcyon.png"
            ></v-img>
          </v-avatar>
          <v-card-title>Youtube or Vimeo</v-card-title>
        </div>
      </v-card></v-col
    >
    <v-col cols="6">
      <v-card id="media-pre-action">
        <v-card-actions>
          <v-btn prepend-icon="mdi-arrow-right-thin">Next</v-btn>
        </v-card-actions>
        <div class="d-flex pa-0">
          <v-avatar class="ma-3" size="125" rounded="0">
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/halcyon.png"
            ></v-img>
          </v-avatar>
          <v-card-title>Youtube or Vimeo</v-card-title>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
});
</script>
<style lang="scss" scoped></style>
