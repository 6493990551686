import apiClient from "@/api";
export default {
  async getChoices({ commit }) {
    var response = await apiClient.Map.getChoices();
    commit("setChoices", response.data);
  },
  async setOpenDialog({ commit }, payload) {
    commit("setOpenDialog", payload);
  },
  async setModeDark({ commit }, payload) {
    commit("setModeDark", payload);
  },
  async setNavigator({ commit }, payload) {
    commit("setNavigator", payload);
  },
  async setAppbar({ commit }, payload) {
    commit("setAppbar", payload);
  },
  async setReloadPage({ commit }) {
    commit("setReloadPage");
  },
  async setAlert({ commit }, payload) {
    console.log(payload);
    commit("setAlert", payload);
  },
};
