import SubmitPost from "./submitPost";
import Map from "./map";
import Auth from "./auth";
import Video from "./video";
import Comments from "./comments";
export default {
  SubmitPost,
  Map,
  Auth,
  Video,
  Comments,
};
