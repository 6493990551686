export default {
  setChoices(state, payload) {
    state.choices = payload;
  },
  setOpenDialog(state, payload) {
    state.openDialog = payload;
  },
  setModeDark(state, payload) {
    window.localStorage.setItem("modeDark", payload);
    state.modeDark = payload;
  },
  setNavigator(state, payload) {
    state.navigator = payload;
  },
  setAppbar(state, payload) {
    state.appbar = payload;
  },
  setReloadPage(state) {
    state.appbar++;
  },
  setAlert(state, payload) {
    state.alert = payload;
  },
};
