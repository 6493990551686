import clients from "./clients";

export default {
  send: function (payload) {
    return clients.apiClient.post(`/video`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
