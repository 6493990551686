import actions from "@/store/modules/auth/actions";
import getters from "@/store/modules/auth/getters";
import mutations from "@/store/modules/auth/mutations";
import state from "@/store/modules/auth/state";

export default {
  actions,
  getters,
  mutations,
  state,
};
