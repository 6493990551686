import actions from "@/store/modules/breadcrumbs/actions";
import getters from "@/store/modules/breadcrumbs/getters";
import mutations from "@/store/modules/breadcrumbs/mutations";
import state from "@/store/modules/breadcrumbs/state";

export default {
  actions,
  getters,
  mutations,
  state,
};
