<template>
  <div class="box-video">
    <div
      class="container-video-child-item"
      @mouseover="hoverTag = true"
      @mouseleave="hoverTag = false"
      @click="$router.push(`${this.data.toPath}`)"
    >
      <v-card class="mb-8">
        <div
          class="el-cover-image"
          v-if="!hoverTag"
          v-bind:style="{
            'background-image': 'url(' + data.cover_picture + ')',
          }"
        ></div>
        <video
          v-if="hoverTag"
          :ref="domVideoElement"
          :id="domVideoElement"
          class="el-video-child"
          @mouseover="onPlay"
          :src="data.video_url"
          playsinline="true"
          x5-playsinline="true"
          webkit-playsinline="true"
          tabindex="2"
          mediatype="video"
        ></video>
        <div class="el-video-view">
          <v-list-item>
            <template v-slot:prepend>
              <span class="font-weight-bold text-untext"
                >{{ data.status.view }}
                <v-icon class="font-weight-bold text-untext"
                  >mdi-play
                </v-icon></span
              >
            </template>
          </v-list-item>
        </div>
      </v-card>
    </div>
    <div class="inner-el-hasgTags px-2">
      <span> {{ data.note }}</span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    data: Object,
  },
  data: function () {
    return {
      domVideoElement: `videoById-${this.$uuid.v4()}`,
      hoverTag: false,
    };
  },
  methods: {
    async onPlay() {
      this.$refs[`${this.domVideoElement}`].play();
    },
  },
  watch: {
    hoverTag: {
      handler(value) {
        return value;
      },
    },
  },
  mounted() {
    console.log(this.data);
  },
});
</script>
<style lang="scss" scoped>
.box-video {
  cursor: pointer;
}

.container-video-child-item {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 140%;
  border-radius: 8px;
  overflow: hidden;

  .el-video-child {
    position: absolute;
    z-index: 500;
    top: 0;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .el-cover-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 700;
    object-fit: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-color: #000;
  }

  .el-video-view {
    position: absolute;
    bottom: 5%;
    z-index: 900;
  }
}

.inner-el-hasgTags {
  height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
</style>
