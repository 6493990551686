<template>
  <v-item-group :class="$vuetify.display.mobile ? 'pa-0' : ''" multiple>
    <v-row :class="$vuetify.display.mobile ? 'pa-0' : ''" align="center">
      <v-col
        v-for="(item, i) in items"
        :key="i"
        :class="$vuetify.display.mobile ? 'pa-0' : ''"
      >
        <v-item v-slot="{ isSelected, toggle }">
          <v-container class="pa-0" @click="toggle">
            <template v-if="item.value === 'more'">
              <v-btn
                id="menu-activator"
                :size="$vuetify.display.mobile ? 'small' : 'medium'"
                @click="handlerOpenDialog('submit_post')"
                :color="isSelected ? 'main' : ''"
                variant="outlined"
              >
                <v-icon color="text-text">{{ item.icon }} </v-icon>
                <v-tooltip activator="parent" location="bottom">More</v-tooltip>
              </v-btn>
              <v-menu activator="#menu-activator">
                <v-list>
                  <v-list-item v-for="index in 3" :key="index" :value="index">
                    <v-list-item-title>{{ index }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                  <v-btn
                    :size="$vuetify.display.mobile ? 'small' : 'medium'"
                    v-bind="props"
                    :color="isSelected ? 'main' : ''"
                    variant="outlined"
                  >
                    <v-icon>{{
                      isSelected ? item.icon : `${item.icon}-outline`
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.value }}</span>
              </v-tooltip>
            </template>
          </v-container>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {
      selection: "",
      items: [
        { icon: "mdi-bell", color: "pink-accent-3", value: "Notification" },
        {
          icon: "mdi-skip-forward",
          color: "pink-accent-3",
          value: "Auto revert",
        },
        {
          icon: "mdi-skip-forward",
          color: "pink-accent-3",
          value: "Auto Next",
        },
        {
          icon: "mdi-skip-previous-circle",
          color: "pink-accent-3",
          value: "Previous",
        },
        {
          icon: "mdi-skip-next-circle",
          color: "pink-accent-3",
          value: "Next",
        },
        { icon: "mdi-share", color: "pink-accent-3", value: "Share" },
        {
          icon: "mdi-dots-horizontal",
          color: "pink-accent-3",
          value: "more",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
});
</script>
<style lang="scss" scoped></style>
