<template>
  <slot name="audio"></slot>
  <v-card>
    <v-tabs v-model="tabChild" bg-color="primary">
      <v-tab value="upload"
        ><v-tooltip text="Upload">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-upload"></v-btn>
          </template> </v-tooltip
      ></v-tab>
      <v-tab value="external_link">
        <v-tooltip text="External Link">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-link-variant"></v-btn>
          </template> </v-tooltip
      ></v-tab>
    </v-tabs>
    <div>
      <v-window v-model="tabChild">
        <v-window-item value="upload"> VIDEO POST </v-window-item>
        <v-window-item value="external_link"> Two </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  setup() {},
  methods: {},
  data() {
    return {
      tabChild: null,
    };
  },
  created() {},
});
</script>

<style></style>
