<template>
  <template></template>
</template>
<script>
import { defineComponent, h } from "vue";
import { mapActions, mapGetters } from "vuex";
import auth from "../../api/auth";
import { VueCookieNext } from "vue-cookie-next";
export default defineComponent({
  setup() {
    if (process.env.VUE_APP_PENDO_API_KEY) {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0))
                  );
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
      })("8ba1a636-6f37-4617-5d92-b8f57360ef6b");
    }
    return () => {
      h();
    };
  },
  methods: {
    ...mapActions("app", ["getChoices"]),
    ...mapActions("auth", ["setIsLoggedIn", "loginInfo"]),
    ...mapGetters("auth", ["isLoggedIn"]),
    async handleCredentialResponse(response) {
      const responsePayload = window.jwt_decode(response.credential);
      if (responsePayload.email_verified)
        await auth.loginGoogle({ ...responsePayload }).then((res) => {
          VueCookieNext.setCookie("email", res.data.data.account.email, {
            expire: "7d",
            path: "",
            secure: true,
            sameSite: "",
            domain: "",
          });
          VueCookieNext.setCookie(
            "access_token",
            res.data.data.token.access_token,
            {
              expire: "7d",
              path: "",
              secure: true,
              sameSite: "",
              domain: "",
            }
          );
          VueCookieNext.setCookie(
            "refresh_token",
            res.data.data.token.refresh_token,
            {
              expire: "7d",
              path: "",
              secure: true,
              sameSite: "",
              domain: "",
            }
          );
          VueCookieNext.setCookie("sub", res.data.data.account.sub, {
            expire: "7d",
            path: "",
            secure: true,
            sameSite: "",
            domain: "",
          });
          this.$store.dispatch("auth/setIsLoggedIn", true);
          this.$store.dispatch("auth/setProfile", res.data);
          location.reload();
        });
    },

    async handlerSnap() {
      if (!VueCookieNext.getCookie("refresh_token")) {
        VueCookieNext.removeCookie("access_token");
        VueCookieNext.removeCookie("email");
        VueCookieNext.removeCookie("refresh_token");
        VueCookieNext.removeCookie("sub");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("profile");
      }
    },
  },
  created() {
    this.loginInfo();
    this.handlerSnap();
    this.getChoices();
  },
  watch: {},
  data() {
    return {};
  },
  mounted() {
    window.google.accounts.id.initialize({
      client_id:
        "778869913096-kiv51j6n42ctu4g2taj8napc8ugqo3ud.apps.googleusercontent.com",
      scope: [
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email",
        "openid",
      ].join(" "),
      callback: this.handleCredentialResponse,
    });
  },
  computed: {},
});
</script>

<style></style>
