export default [
  {
    path: "/",
    name: "user",
    view: "PageHome",
  },
  {
    path: "/foryou",
    name: "foryou",
    view: "PageHome",
  },
  {
    path: "/guide",
    name: "guide-view",
    view: "PageGuide",
  },
  {
    path: "/@:sub",
    name: "profile",
    view: "PageProfile",
  },
  {
    path: "/tag/:hashtag",
    name: "profile",
    view: "PageHashtag",
  },
  {
    path: "/@:idAccount/video/:idVideo",
    name: "video-detail",
    view: "PageVideo",
  },
  {
    path: "/upload",
    name: "guide-view",
    view: "PageCreateVideo",
    needLogin: true,
  },
  {
    path: "/404",
    name: "notfound",
    view: "PageNotFound",
  },
];
