import { VueCookieNext } from "vue-cookie-next";
import clients from "./clients";

export default {
  create: function (payload) {
    return clients.apiClient.post(`/video/upload`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: VueCookieNext.getCookie("access_token"),
      },
    });
  },
  getById: function (payload) {
    return clients.apiClient.get(
      `video/@${payload.idAccount}/${payload.idVideo}`
    );
  },
  getList: function () {
    return clients.apiClient.get(`video/get-list`);
  },
};
