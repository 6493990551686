import CoreAInit from "@/components/app/AppInit.vue";
import CoreAView from "@/components/app/AppView.vue";
import CoreADrawer from "@/components/app/AppDrawer.vue";
import CoreAToolbar from "@/components/app/AppToolbar.vue";
import CoreAppBar from "@/components/app/AppBar.vue";
import CoreABottomNavigation from "@/components/app/AppBottomNavigation.vue";
import CoreAStatusMessegeAnimation from "@/components/app/AppStatusMessegeAnimation.vue";
export default {
  CoreAInit,
  CoreAView,
  CoreADrawer,
  CoreAToolbar,
  CoreAppBar,
  CoreABottomNavigation,
  CoreAStatusMessegeAnimation,
};
