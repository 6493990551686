<template>
  <video></video>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
});
</script>
<style lang="scss" scoped></style>
