<template>
  <v-dialog v-model="dialog" color="sunface">
    <v-card style="overflow-y: hidden" max-width="500" class="mx-auto">
      PROFILE
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
  data() {
    return {
      dialog: true,
    };
  },
  watch: {
    dialog: {
      handler(value) {
        if (value === false) {
          this.$emit("update:openDialog", "");
        }
        return (this.dialog = value);
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.blog-img {
  height: auto;
  max-width: 100%;
}
</style>
