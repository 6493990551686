<template>
  <v-app-bar dark class="bg-transparent pa-md-4">
    <v-row dense class="align-center">
      <v-col md="8">
        <v-card flat class="pa-md-0 bg-transparent">
          <v-list
            density="compact"
            class="d-flex justify-start bg-transparent align-center"
          >
            <v-list-item
              v-for="(link, i) in links"
              :key="i"
              :value="link"
              active-color="primary"
              class="bg-transparent"
            >
              <v-list-item
                @click="$emit(updateComponentKey)"
                :to="link.to"
                :data-test="link.name"
                flat
                link
              >
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col md="4" class="d-flex justify-end">
        <v-btn icon fab color="pink">
          <v-icon dark> mdi-menu </v-icon>
        </v-btn>
        <div class="d-flex justify-space-around">
          <v-btn icon>
            <v-icon dark>mdi-reload </v-icon>
            <v-tooltip activator="parent" location="bottom" style="top: 0"
              >Tải lại bộ nhớ</v-tooltip
            >
          </v-btn>
        </div>
        <v-btn icon fab> &deg;C </v-btn>
        <v-btn icon> &deg;F </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"
              dark
              v-bind="props"
              min-width="48"
              height="48"
            >
              <v-avatar height="48" width="48" class="rounded-lg">
                <img
                  height="48"
                  width="48"
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    componentkey: {
      type: Number,
    },
  },
  data() {
    return {
      selectedItem: 1,
      links: [
        { to: "/weather/today", name: "Hôm nay" },
        { to: "/weather/week", name: "Tuần này" },
      ],
      account_: [
        { title: "weather/today", name: "Hôm nay" },
        { title: "weather/week", name: "Tuần này" },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped></style>
