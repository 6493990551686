<template>
  <v-card
    width="692"
    max-width="692"
    class="mb-8"
    color="backgroundBody"
    elevation="0"
  >
    <v-row>
      <v-col cols="12">
        <slot name="user"></slot>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="9">
            <slot name="video"> </slot>
          </v-col>
          <v-col cols="3">
            <slot name="features"> </slot>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({});
</script>
<style lang="scss" scoped></style>
