<template>
  <v-dialog v-model="dialog" color="sunface">
    <v-card style="overflow-y: hidden" max-width="500" class="mx-auto">
      <v-card-actions class="justify-end">
        <v-btn
          @click="dialog = false"
          icon="mdi-close-outline"
          size="small"
          color="error"
        ></v-btn>
      </v-card-actions>
      <v-row class="d-flex pa-4">
        <v-col cols="12">
          <h2 class="text-center">Đăng nhập vào Tốp Tốp</h2>
        </v-col>
        <v-col cols="12">
          <v-row no-gutter>
            <v-col cols="12" class="auth0-login">
              <div id="googleLogin" class="w-100 text-gray font-weight-bold">
                <slot
                  ><v-btn color="error" variant="outlined" class="w-100 mt-2"
                    >Features signin google not working</v-btn
                  ></slot
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn class="mr-2" color="success" prepend-icon="mdi-account"
            >Profile</v-btn
          >
          <v-btn class="mr-auto" prepend-icon="mdi-exit-to-app" @click="logout"
            >Logout</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
  props: {
    openDialog: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: true,
      fbLoginId: `fb-login-id-${this.$uuid.v4()}`,
    };
  },
  methods: {
    async logout() {
      this.handlerOpenDialog("");
      Window.google.accounts.id.disableAutoSelect();
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (value === false) {
          this.$emit("update:openDialog", "");
        }
        return (this.dialog = value);
      },
    },
  },
  mounted() {
    window.google.accounts.id.renderButton(
      document.getElementById("googleLogin"),
      { theme: "outline", size: "large" }
    );
    window.google.accounts.id.prompt();
  },
});
</script>
<style lang="scss" scoped>
.blog-img {
  height: auto;
  max-width: 100%;
}
</style>
