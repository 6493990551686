<template>
  <v-sheet class="pa-2">
    <v-card :rounded="false" class="pa-6 mt-4" id="comments">
      <v-card-title>Leave your comment</v-card-title>
    </v-card>
    <component-comments-form></component-comments-form>
    <component-comments-list></component-comments-list>
    <v-divider inset></v-divider>
  </v-sheet>
</template>
<script>
import { defineComponent } from "vue";
import commentMixin from "@/mixins/comments";
export default defineComponent({
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  mixins: [commentMixin],
});
</script>
<style lang="scss" scoped></style>
