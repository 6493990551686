<template>
  <div style="position: relative">
    <slot name="content"></slot>
    <div
      v-if="loading"
      style="position: fixed"
      class="ui-position-xy-center fixed-mask"
    >
      <v-progress-circular
        style="position: absolute"
        class="ui-position-xy-center"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
});
</script>
