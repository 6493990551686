import { defineComponent } from "vue";
import { mapGetters } from "vuex";
export default defineComponent({
  computed: {
    ...mapGetters("breadcrumbs", ["getBreadcrumbs"]),
  },
  mounted() {
    if (!this.$_.isNil(this.getBreadcrumbs)) {
      this.breadcrumbs = this.getBreadcrumbs;
      console.error("ERR");
    }
  },
  data() {
    return {
      breadcrumbs: {
        name: "Home",
        items: [],
      },
    };
  },
  beforeCreate() {},
});
