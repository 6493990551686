<template>
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col cols="12">
        <v-textarea
          label="Your comment"
          variant="outlined"
          placeholder="Placeholder"
          required
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Your email"
          required
          placeholder="Placeholder"
          variant="outlined"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Your name"
          required
          placeholder="Placeholder"
          variant="outlined"
        ></v-text-field>
      </v-col>
      <v-card-actions>
        <v-btn color="main" class="mr-4" variant="outlined">
          Post comment
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-form>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {
    items: {
      handler(value) {
        return value;
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>
<style lang="scss" scoped></style>
